.tessel {
  background: var(--color-ui-dark-bg);
  box-sizing: border-box;
}

.tessel-content {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.tessel-drop-capture {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.tessel-drop-capture--dragging {
  opacity: 0.7;
}

.tessel-drop-marker {
  position: absolute;
  background: var(--color-ui-drop-bg);
  border: 2px solid var(--color-ui-drop-bg-border);
  border-radius: 3px;
  display: none;
  pointer-events: none;
}

.tessel-drop-marker.drop-left {
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  width: 33%;
}

.tessel-drop-marker.drop-right {
  display: block;
  right: 0;
  top: 0;
  bottom: 0;
  width: 33%;
}

.tessel-drop-marker.drop-top {
  display: block;
  left: 0;
  right: 0;
  top: 0;
  height: 33%;
}

.tessel-drop-marker.drop-bottom {
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  height: 33%;
}

.tessel-split:hover {
  background: var(--color-ui-light-selected-hover);
}

.tessel-split--row {
  height: 100%;
  width: 5px;
  min-width: 5px;
  cursor: col-resize;
}

.tessel-split--column {
  height: 5px;
  min-height: 5px;
  width: 100%;
  cursor: row-resize;
}

.tessel-window {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: var(--color-ui-light-bg);
  border-radius: 3px;
}

.tessel-window-titlebar {
  background: var(--color-ui-surface-bg);
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 3px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  border-bottom: 2px solid var(--color-ui-surface-border);
}

.tessel-window-title {
  font-size: 0.9em;
  font-weight: 600;
  cursor: default;
  user-select: none;
}

.tessel-window-controls {
  margin-left: auto;
  line-height: 0;
}

.tessel-window-controls-close {
  cursor: pointer;
  stroke: black;
}

.tessel-window-content {
  width: 100%;
  height: 100%;
  padding: 2px;
  flex-shrink: 1;
  min-height: 0;
  box-sizing: border-box;
}
