.element-tray {
  background: var(--color-ui-light-bg);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 3px;
  display: flex;
  flex-direction: column;
}

.element-tray-search {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 5px;
}

.element-tray-element-container {
  overflow-y: scroll;
  width: 100%;
}

.element-tray-element-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.element-tray-category {
  box-sizing: border-box;
  font-weight: 600;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 2px;
  margin-bottom: 3px;
}

.element-tray-item {
  box-sizing: border-box;
  padding: 6px 7px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: var(--color-ui-light-bg);
}

.element-tray-item:hover {
  background: var(--color-ui-light-hover);
}

.element-tray-item-preview {
  width: 30px;
  height: 30px;
  padding-right: 10px;
}
