html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Titillium Web", sans-serif;
  color: var(--color-text-default);
}

a {
  color: var(--color-text-link);
}
a:hover {
  color: var(--color-text-link-hover);
}
