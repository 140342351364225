.button {
  margin: 0 2px;
  vertical-align: middle;
}

.button-play {
  fill: darkgreen;
  cursor: pointer;
}

.button-play:hover {
  fill: greenyellow;
}

.button-stop {
  fill: darkred;
  cursor: pointer;
}

.button-stop:hover {
  fill: red;
}

.button-pause {
  fill: darkorange;
  cursor: pointer;
}

.button-pause:hover {
  fill: orange;
}

.button-pause.selected {
  fill: blue;
}

.button-step {
  fill: darkorange;
  cursor: pointer;
}

.button-step:hover {
  fill: orange;
}

.button-step.selected {
  fill: blue;
}

.button--disabled {
  fill: grey;
  cursor: unset;
}

.button--disabled:hover {
  fill: grey;
}

.simrate {
  padding-right: 5px;
}
