.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-row.flex-reverse {
  flex-direction: row-reverse;
}

.flex-column.flex-reverse {
  flex-direction: column-reverse;
}

.flexitem-grow {
  flex-grow: 1;
}
.flexitem-shrink {
  flex-shrink: 1;
}

.flexitem-fix {
  flex-grow: 0;
  flex-shrink: 0;
}

.flex-row > .flexitem-shrink {
  min-width: 0;
}
.flex-column > .flexitem-shrink {
  min-height: 0;
}
