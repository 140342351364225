.dialog {
  width: 588px;
  background: var(--color-ui-light-bg);
  border-radius: 5px;
  min-height: 100px;
  padding: 15px 20px;
}

.dialog-title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 10px;
}

.dialog-footer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
