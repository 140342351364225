.circuitstree {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

.circuitstree-item {
  width: "100%";
  background: var(--color-ui-light-bg);
}
