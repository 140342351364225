.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 15%);
}

.modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
