:root {
  --color-element-neutral: black;
  --color-element-unwired: lightgrey;
  --color-element-selected: goldenrod;
  --color-element-powered: rgb(0, 230, 0);
  --color-element-wiretarget: rgb(15, 159, 255);

  --color-text-default: black;
  --color-text-link: rgb(15, 159, 255);
  --color-text-link-hover: rgb(92, 190, 255);

  --color-ui-primary-bg: rgb(0, 182, 0);
  --color-ui-primary-bg-hover: rgb(0, 205, 0);

  --color-ui-light-bg: white;
  --color-ui-light-border: rgb(117, 117, 117);
  --color-ui-light-hover: rgb(218, 218, 218);
  --color-ui-light-selected: rgb(209, 224, 255);
  --color-ui-light-selected-hover: rgb(179, 194, 225);
  --color-ui-light-text: black;
  --color-ui-light-text-disabled: rgb(218, 218, 218);

  --color-ui-surface-bg: rgb(221, 221, 221);
  --color-ui-surface-bg-disabled: rgb(230, 230, 230);
  --color-ui-surface-border: rgb(209, 209, 209);
  --color-ui-surface-hover: rgb(231, 231, 231);
  --color-ui-surface-text: black;
  --color-ui-surface-text-disabled: rgb(175, 175, 175);

  --color-ui-dark-bg: rgb(117, 117, 117);

  --color-ui-drop-bg: rgba(0, 153, 255, 0.445);
  --color-ui-drop-border: rgb(0, 153, 255);

  --color-ui-tooltip-bg: rgb(49, 49, 49);
  --color-ui-tooltip-text: rgb(212, 212, 212);
}
