.tutorial {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.tutorial-tooltip {
  z-index: 10;
}

.tutorial-tooltip-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tutorial-backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.192);
  z-index: 1;
}
